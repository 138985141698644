@import './variables.scss';
@import './button.scss';
@import './forms.scss';
@import './popup.scss';

* {
  user-select: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'NotoSans-SemiCondensedBold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-dots {
  margin: 0 0 2vh 0;
  display: flex !important;

  li {
    margin: 0 5px;

    &.slick-active {
      button {
        background-color: #fcef24;
        color: #000;
      }
    }

    button {
      width: 24px;
      height: 24px;
      border: none;
      border-radius: 100%;
      background-color: #fff;
      box-shadow: 0 0 5px 1px #a20e02;
      color: #000;
      line-height: 24px;
      text-align: center;
      cursor: pointer;
      outline: none;
      padding: 0;
    }
  }
}

.slick-arrow {
  position: absolute;
  bottom: 2vh;
  font-size: 0;
  line-height: 0;
  height: 24px;
  width: 20.78px;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  filter: drop-shadow(0 0 5px #a20e02);
  z-index: 1;

  &::after {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    background: #fff;
  }
}

.slick-prev {
  left: calc(50% - 34px * 3);

  &::after {
    clip-path: polygon(95% 5%, 5% 50%, 95% 95%);
  }
}

.slick-next {
  right: calc(50% - 34px * 3);

  &::after {
    clip-path: polygon(5% 5%, 5% 95%, 95% 50%);
  }
}
